import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {MatDialog} from '@angular/material/dialog';
import {MatStepper} from '@angular/material/stepper';
import {ActivatedRoute, Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {Observable, Subject} from 'rxjs';
import {first} from 'rxjs/operators';
import {Bike} from 'src/app/models/bike';
import {SlugifyPipe} from 'src/app/pipes/slugify.pipe';
import {BikesService} from 'src/app/services/bikes.service';
import {CrmNotificationsService} from 'src/app/services/crm-notifications.service';
import {UsersService} from 'src/app/services/users.service';
import {ClientsListComponent} from '../clients-list/clients-list.component';
import {TitleService} from '../../services/title.service';

import bikeModelsDB from '../../../assets/bike_types_bbdd/bike_models_db.json';

import Fuse from 'fuse.js';
import {User} from '../../models/user';

@Component({
  selector: 'app-new-bss-stepper',
  templateUrl: './new-bss-stepper.component.html',
  styleUrls: ['./new-bss-stepper.component.scss'],
  providers: [SlugifyPipe]
})
export class NewBssStepperComponent implements OnInit, OnDestroy {
  @ViewChild('stepper') private myStepper: MatStepper;

  private onDestroy$: Subject<void> = new Subject<void>();

  completedClientStep = false;
  completedBikeStep = false;

  submitInProgress = false;

  clientFormGroup: UntypedFormGroup;
  bikeFormGroup: UntypedFormGroup;

  bikeTypes: object[];
  // bikesTypeOptions$: Observable<BikeTypeGroup[]>;

  selectedClient: User;

  currenClientBikes: Bike[] = [];

  usersAutoCompletePhone;
  usersAutoCompleteEmail;
  usersAutoCompleteName;

  isBudget = false;

  constructor(
    public dialog: MatDialog,
    private crmNotificationService: CrmNotificationsService,
    private translate: TranslateService,
    private router: Router,
    private titleService: TitleService,
    // tslint:disable-next-line:variable-name
    private _formBuilder: UntypedFormBuilder,
    private userService: UsersService,
    private bikesService: BikesService,
    private slugifyPipe: SlugifyPipe,
    private route: ActivatedRoute
  ) { }

  ngOnDestroy(): void {
    this.onDestroy$.next();
  }

  ngOnInit(): void {
    this.titleService.setTitle('');
    this.bikeTypes = this.userService.getCustomConfig().get_bike_types();

    if (this.route.snapshot.paramMap.has('budget') && this.route.snapshot.paramMap.get('budget').toLowerCase() === 'true') {
      this.isBudget = true;
    }

    // TODO: validate phone number (take into account +, (), -, ., spaces, country code, etc.)
    // TODO: add at least min length or something to name
    this.clientFormGroup = this._formBuilder.group({
      name: ['', Validators.required],
      email: ['', Validators.email],
      phone: ['', Validators.maxLength(17)],
    });

    // TODO: add some validation to bike name also
    this.bikeFormGroup = this._formBuilder.group({
      bike_name: ['', Validators.required],
      bike_type: ['', Validators.required],
      bike_picture: ['']
    });

    // this.bikesTypeOptions$ = this.bikeFormGroup.get('bike_type')!.valueChanges
    //   .pipe(
    //     takeUntil(this.onDestroy$),
    //     startWith(''),
    //     map(value => this._filterBikeTypes(value))
    //   );
  }

  // private _filterBikeTypes(value: string): BikeTypeGroup[] {
  //   if (value) {
  //     return bikeTypesGroups
  //       .map(group => ({ subtype: group.subtype, names: _filter(group.names, value) }))
  //       .filter(group => group.names.length > 0);
  //   }
  //
  //   return bikeTypesGroups;
  // }

  getErrorMessageI18N(field: string): string {
    if (!this.clientFormGroup) {
      return '';
    }

    if (this.clientFormGroup.get(field).hasError('required')) {
      return 'YOU_MUST_ENTER_A_VALUE';
    }

    if (field === 'email') {
      // TODO: translate
      return this.clientFormGroup.get('email').hasError('email') ? 'NOT_A_VALID_EMAIL' : '';
    }
    if (field === 'phone') {
      return this.clientFormGroup.get('phone').hasError('maxlength') ? 'PHONE_NUMBER_TOO_LONG' : '';
    }
    return '';
  }

  searchExistingClient(): void {
    const dialogRef = this.dialog.open(ClientsListComponent, {
      height: '90%',
      width: '90%',
      maxWidth: '90%',
      panelClass: 'no-padding-dialog-container',
      data: {
        mode: 'client-selector'
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      const r: User = result;

      if (!r || !r.id) {
        return;
      }

      this.selectExistingClient(result);
    });
  }

  selectExistingClient(user: User): void {
    this.completedClientStep = true;

    this.selectedClient = user;

    // TODO: why we need this timeout?
    setTimeout(() => {
      if (this.selectedClient.id) {
        this.myStepper.next();
      }
    }, 1);

    this.bikesService.getOwned(this.selectedClient.id).subscribe(x => this.currenClientBikes = x);
  }

  async onUserAutocompleteSelected(e): Promise<void> {
    // console.log(e.option.value);
    const user = e.option.value;
    this.selectExistingClient(user);
  }

  async onPhoneInput(e): Promise<void> {
    // TODO deduplicate code with onNameInput and onMailInput
    const phone = this.clientFormGroup.get('phone').value;
    if (phone.length < 5) {
      this.usersAutoCompletePhone = [];
      return;
    }
    const apiFilters = {
      is_business_client: true,
      is_business_owner: false,
      is_business_employee: false,
      is_business_supplier: false
    };
    const p = await this.userService.getPaginatedList(apiFilters, phone, false, 1, 4, 'search', 'phone_number').toPromise();
    this.usersAutoCompletePhone = p.results;
  }

  async onNameInput(e): Promise<void> {
    const email = this.clientFormGroup.get('name').value;
    if (email.length < 4) {
      this.usersAutoCompleteName = [];
      return;
    }
    const apiFilters = {
      is_business_client: true,
      is_business_owner: false,
      is_business_employee: false,
      is_business_supplier: false
    };
    const p = await this.userService.getPaginatedList(apiFilters, email, false, 1, 5, 'search', 'name').toPromise();
    this.usersAutoCompleteName = p.results;
  }

  async onMailInput(e): Promise<void> {
    const email = this.clientFormGroup.get('email').value;
    if (email.length < 5) {
      this.usersAutoCompleteEmail = [];
      return;
    }
    const apiFilters = {
      is_business_client: true,
      is_business_owner: false,
      is_business_employee: false,
      is_business_supplier: false
    };
    const p = await this.userService.getPaginatedList(apiFilters, email, false, 1, 4, 'search', 'email').toPromise();
    this.usersAutoCompleteEmail = p.results;
  }

  async onSubmitClient(): Promise<void> {
    if (this.clientFormGroup.valid) {
      // this.selectedClient = await this._createClient();

      // this._createClient()
      //   .then(x => {
      //     this.completedClientStep = true;
      //     if (x.id) {
      //       this.myStepper.next();
      //     }
      //   })
      //   .catch(async (x) => {
      //     this.crmNotificationService.error(await this.translate.get('ERROR_CREATING_USER').toPromise());
      //   })
      //   .then(x => this.submitInProgress = false);

      this._createClient()
        .pipe(first())
        .subscribe({
          next: (x) => {
            this.selectedClient = x;
            this.submitInProgress = false;
            this.completedClientStep = true;

            // TODO: why we need this timeout?
            setTimeout(() => {
              if (this.selectedClient.id) {
                this.myStepper.next();
              }
            }, 1);
          },
          error: async () => {
            this.crmNotificationService.error(await this.translate.get('ERROR_CREATING_USER').toPromise());
            this.submitInProgress = false;
          }
        });

    }
  }

  onBikeNameInput(e): void {
    // TODO: call this when the user stops typing, not continuisly
    // TODO: move this to the bike model, as a detect type, name, etc method
    const bikeName = e.target.value;
    // console.log('onBikeNameInput: ', e.target.value);
    if (bikeName.length < 4) {
      return;
    }

    const fuseOptions = {
      // isCaseSensitive: false,
      // includeScore: false,
      // shouldSort: true,
      // includeMatches: false,
      // findAllMatches: false,
      // minMatchCharLength: 1,
      // location: 0,
      threshold: 0.5,
      // distance: 100,
      // useExtendedSearch: false,
      // ignoreLocation: false,
      // ignoreFieldNorm: false,
      // fieldNormWeight: 1,
      keys: [
        'name',
      ]
    };

    // console.log(bikeModelsDB.models);
    // TODO: create fuseBikeModel only once not on every method call
    // TODO: if in the name there is already a bike type, mtb, plegagle, whatever, just set the bike type to that!
    const fuseBikeModel = new Fuse(bikeModelsDB.models, fuseOptions);

    const candidates = fuseBikeModel.search(bikeName);
    console.log(candidates);
    if (candidates.length > 0) {
      // TODO: return the closes match, not the first
      console.log('brand candidate found: ', candidates[0].item);
      // TODO: the type has to be the exact same name, so we should also
      //  do a fuzzy search between bbdd types and available types
      this.bikeFormGroup.get('bike_type').setValue(candidates[0].item.bike_type);

    }

  }

  onAddBikePictureChange(event): void {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      this.bikeFormGroup.get('bike_picture').setValue(file);
    }
  }

  _createClient(): Observable<User> {
    this.submitInProgress = true;

    const formData = new FormData();
    formData.append('name', this.clientFormGroup.get('name').value);
    const usernameSlug = this.slugifyPipe.transform(this.clientFormGroup.get('name').value);
    formData.append('username', usernameSlug);

    formData.append('email', this.clientFormGroup.get('email').value);

    // remove whitespaces
    formData.append('phoneNumber', this.clientFormGroup.get('phone').value.replace(/\s/g, ''));
    formData.append('isBusinessClient', true.toString());
    formData.append('isBusinessEmployee', false.toString());
    // formData.append('profilePicture', this.clientFormGroup.get('profile_picture').value);

    return this.userService.create(formData);
  }

  async onSubmitBike(): Promise<void> {
    if (this.bikeFormGroup.valid) {

      const createdBike = await this._createBike();
      if (createdBike.id) {
        this.router.navigate(['/servicesheets/create', {bikeId: createdBike.id, budget: this.isBudget}], { replaceUrl: true });
      } else {
        this.crmNotificationService.error(
          await this.translate.get('FEEDBACK_MESSAGES.CHECK_INTERNET_CONNECTION').toPromise(),
          await this.translate.get('FEEDBACK_MESSAGES.PROBLEM_CREATING_BIKE').toPromise()
        );
      }
    }
  }

  async _createBike(): Promise<Bike> {
    const formData = new FormData();

    formData.append('owner', this.selectedClient.id);
    formData.append('name', this.bikeFormGroup.get('bike_name').value);
    if (this.bikeFormGroup.get('bike_type').value) {
      formData.append('type', this.bikeFormGroup.get('bike_type').value);
    }

    if (this.bikeFormGroup.get('bike_picture').value != null) {
      formData.append('picture', this.bikeFormGroup.get('bike_picture').value);
    }

    return this.bikesService.create(formData)
      .pipe(
        first())
      .toPromise();
  }

}
