import {AfterViewInit, Component, Inject, OnDestroy, OnInit, Optional} from '@angular/core';
import {UntypedFormBuilder} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {ActivatedRoute, Router} from '@angular/router';
import {TitleService} from 'src/app/services/title.service';
import {
  FilterMetaData,
  FilterOption,
  FilterTypes,
  ItemSimpleFiltrableListAbstractComponent
} from '../../components/abstract-simple-filtrable-item-list/abstract-simple-filtrable-item-list.component';
import {PosOrdersService} from '../../services/pos-orders.service';
import {Order} from '../../models/pos_order';
import {Fab, FabTypes} from '../../components/fab-custom/fab-interface';
import {NativeInterfacesService} from '../../services/native-interfaces.service';
import {UsersService} from '../../services/users.service';
import {PAYMENT_METHOD_I18N_KEY, PAYMENT_STATUS_I18N_KEY, PaymentStatus} from '../../models/billable_item';
import {MediaObserver} from '@ngbracket/ngx-layout';
import {PrivacyModeService} from '../../services/privacy-mode.service';


@Component({
  selector: 'app-pos-orders-list',
  templateUrl: '../../components/abstract-simple-filtrable-item-list/abstract-simple-filtrable-item-list.component.html',
  styleUrls: ['../../components/abstract-simple-filtrable-item-list/abstract-simple-filtrable-item-list.component.scss']
})
export class PosOrdersListComponent extends ItemSimpleFiltrableListAbstractComponent<Order>
  implements OnInit, OnDestroy, AfterViewInit {
  // TODO: rename to sales, not orders
  // TODO: implement filters like, payment status
  // TODO: be able to pass a user, and get all his orders

  pageTitleI18N = 'SALES';

  urlBaseDetailItem = 'pos/orders';

  displayedColumnsMetaData = null;

  filtersMetaData = {
    openedBy: new FilterMetaData('OPENED_BY', FilterTypes.select_single, [new FilterOption('default_null_filter', 'LOADING_AND_THREE_DOTS')]),
    paymentMethod: new FilterMetaData(
      'PAYMENTS.PAYMENT_METHOD',
      FilterTypes.select_single,
      [
        new FilterOption('default_null_filter', 'PAYMENTS.ALL')
      ]),
    paymentStatus: new FilterMetaData(
      'PAYMENTS.PAYMENT_STATUS',
      FilterTypes.select_single,
      [
        new FilterOption('default_null_filter', 'PAYMENTS.ALL'),
        new FilterOption(PaymentStatus.Paid, PAYMENT_STATUS_I18N_KEY[PaymentStatus.Paid]),
        new FilterOption(PaymentStatus.PendingAndPartiallyPaid, PAYMENT_STATUS_I18N_KEY[PaymentStatus.Pending]),
        // new FilterOption(PaymentStatus.Pending, PAYMENT_STATUS_I18N_KEY[PaymentStatus.Pending]),
        // new FilterOption(PaymentStatus.PartiallyPaid, PAYMENT_STATUS_I18N_KEY[PaymentStatus.PartiallyPaid]),
        new FilterOption(PaymentStatus.Unknown, PAYMENT_STATUS_I18N_KEY[PaymentStatus.Unknown]),
        new FilterOption(PaymentStatus.AdvancePayment, PAYMENT_STATUS_I18N_KEY[PaymentStatus.AdvancePayment]),
      ])
  };

  primaryFab = new Fab('NEW_SALE', 'add', 'fab_add_new_order', FabTypes.singleAction);
  secondaryFab = null;

  constructor(
    @Optional() public dialogRef: MatDialogRef<PosOrdersListComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) protected data: { mode: string },
    protected router: Router,
    protected titleService: TitleService,
    private userService: UsersService,
    protected activatedRoute: ActivatedRoute,
    protected formBuilder: UntypedFormBuilder,
    protected posOrdersService: PosOrdersService,
    protected nativeInterfacesService: NativeInterfacesService,
    protected dialog: MatDialog,
    protected media$: MediaObserver,
    protected privacyModeService: PrivacyModeService
  ) {
    super(
      dialogRef,
      data,
      router,
      titleService,
      activatedRoute,
      formBuilder,
      posOrdersService,
      nativeInterfacesService,
      dialog,
      media$,
      userService,
      privacyModeService
    );

    // TODO: move to abstract class
    const enabledPaymentMethods = this.userService.getCustomConfig().get_payment_methods();
    const filterOptionsPaymentMethod = [];
    filterOptionsPaymentMethod.push(new FilterOption('default_null_filter', 'PAYMENTS.ALL'));
    for (const paymentMethod of enabledPaymentMethods) {
      filterOptionsPaymentMethod.push(new FilterOption(paymentMethod, PAYMENT_METHOD_I18N_KEY[paymentMethod]));
    }

    this.filtersMetaData.paymentMethod = new FilterMetaData(
      'PAYMENTS.PAYMENT_METHOD',
      FilterTypes.select_single,
      filterOptionsPaymentMethod);
  }

  ngOnInit(): void {
    if (this.userService.userMe && this.userService.userMe.workshop) {
      // TODO: this shluld not happen, we should wait for the user to be loaded (maybe on a lodcing screen?)
      // tslint:disable-next-line
      this.defaultFilters['workshop_id'] = this.userService.userMe.workshop;
    }
    super.ngOnInit();

    // TODO: remove, if parentId is not null, just create a ticket for that user
    if (this.parentId) {
      this.primaryFab = null;
    }

    if (this.dialogRef != null) {
      this.dialogMode = true;
      if (this.data.mode === 'floating-selector') {
      }
    } else {
    }

    if (this.parentId && this.parentApiFilter === 'client__id') {
      // We are inside a bike service sheet list, we can add a new service sheet to this bike
      this.addButtonI18N = 'NEW_SALE';
    }

    this.populateOpenedByFilter();
  }

  configureColumns(): void {
    this.displayedColumnsCustomConfig = this.userService.getCustomConfig().get_shop_ticket_list_columns();
    super.configureColumns();
  }

  populateOpenedByFilter(): void {
    this.userService.getStaff().subscribe(staffUsers => {
      const filterOptions = [new FilterOption('', 'All')];
      for (const staffUser of staffUsers) {
        filterOptions.push(new FilterOption(staffUser.id, staffUser.name));
      }
      this.filtersMetaData.openedBy = new FilterMetaData('OPENED_BY', FilterTypes.select_single, filterOptions);
    });
  }

  onFabAction(actionId: string): boolean {
    if (actionId === 'fab_add_new_order') {
      this.router.navigate(['/pos/orders/create']);
      return true;
    }
    console.log(`TODO: implement ${actionId}`);
    return true;
  }
}
