export abstract class AbstractBaseItem {
  id: string;

  prettyId: string;
  shortPrettyId: string;

  // has all the changes already in sync with the api/backend, you should put this to false when making any changes, and back to true
  //  when the api responds successfully (maybe we can inject this on the api responses?)
  // tslint:disable-next-line:variable-name
  _backendSynced: (null | boolean);

  static isSyncing(obj: AbstractBaseItem): boolean {
    return (obj._backendSynced != null && !obj._backendSynced);
  }

  static str(obj: AbstractBaseItem): string {
    if ('name' in obj) {
      // tslint:disable-next-line:no-string-literal
      return obj['name'];
    }
    if ('description' in obj) {
      // tslint:disable-next-line:no-string-literal
      return obj['description'];
    }
    if ('taskDescription' in obj) {
      // tslint:disable-next-line:no-string-literal
      return obj['taskDescription'];
    }
    // TODO: report on sentry as handled, should not happen
    return obj.shortPrettyId;
  }

  static pic(obj: AbstractBaseItem): string {
    if ('picture' in obj) {
      // tslint:disable-next-line:no-string-literal
      return obj['picture'];
    }
    if ('logo' in obj) {
      // tslint:disable-next-line:no-string-literal
      return obj['logo'];
    }
    if ('profilePicture' in obj) {
      // tslint:disable-next-line:no-string-literal
      return obj['profilePicture'];
    }
    if ('main_picture' in obj) {
      // tslint:disable-next-line:no-string-literal
      return obj['main_picture'];
    }
    if ('profile_picture' in obj) {
      // tslint:disable-next-line:no-string-literal
      return obj['main_picture'];
    }
    // TODO: report on sentry as handled, should not happen
    return null;
  }
}
