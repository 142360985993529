<div class="base-paddings">
    <div class="contrast-bg">
        <h1 class="p2" *ngIf="!isBudget">{{ "NEW_SERVICE_SHEET" | translate }}</h1>
        <h1 class="p2" *ngIf="isBudget">{{ "NEW_BUDGET" | translate }}</h1>

        <mat-divider></mat-divider>

        <!-- TODO: split forms of client and bike to custom components? -->
        <mat-vertical-stepper [linear]="true" #stepper>
            <!-- Step 1: Client data -->
            <!-- TODO: enable editable (so we can go back) -->
            <mat-step state="face" [completed]="completedClientStep" [editable]="false">
                <ng-template matStepLabel>{{ "CLIENT_CONTACT_INFO" | translate }} <span *ngIf="this.selectedClient">: {{ this.selectedClient.name }} - {{ this.selectedClient.phoneNumber }} - {{ this.selectedClient.email }}</span></ng-template>

                <button mat-stroked-button color="primary" (click)="searchExistingClient()" data-cy="button-search-existing-client">
                    <mat-icon>folder_shared</mat-icon>
                    <span class="p1">
                        {{ "SEARCH_EXISTING_CLIENT" | translate }}
                    </span>
                </button>

                <form [formGroup]="clientFormGroup" (ngSubmit)="onSubmitClient()" class="mt2">
                    <div fxLayout="column">
                        <mat-form-field fxFlex appearance="outline">
                            <mat-label>{{ 'NAME' | translate }}</mat-label>

                            <mat-autocomplete #autoName="matAutocomplete" (optionSelected)="onUserAutocompleteSelected($event)">
                              <mat-option *ngFor="let user of usersAutoCompleteName" [value]="user">
                                {{ user.name }} - {{ user.email }} - {{ user.phoneNumber }}
                              </mat-option>
                            </mat-autocomplete>

                            <!-- https://stackoverflow.com/questions/48397757/angular-material-hide-autocomplete-panel-when-user-hits-enter-key -->
                            <input
                                data-cy="client-name"
                                matInput
                                type="text"
                                appAutofocus
                                #trigger="matAutocompleteTrigger"
                                (keydown.enter)="trigger.closePanel()"
                                (input)="onNameInput($event)"
                                formControlName="name"
                                name="name"
                                [matAutocomplete]="autoName"
                                autocomplete="off"
                                required>
                          <mat-error *ngIf="clientFormGroup.get('name').invalid">{{ getErrorMessageI18N('name') | translate  }}</mat-error>

                        </mat-form-field>
                        <div >
                        <div fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="space-between">
                            <mat-form-field fxFlex="49" fxFlex.lt-md="100" appearance="outline">
                                <mat-label>{{ 'EMAIL' | translate }}</mat-label>

                                <mat-autocomplete #autoEmail="matAutocomplete" autoActiveFirstOption (optionSelected)="onUserAutocompleteSelected($event)">
                                  <mat-option *ngFor="let user of usersAutoCompleteEmail" [value]="user">
                                    {{ user.email }} - {{ user.name }}
                                  </mat-option>
                                </mat-autocomplete>

                                <input
                                    data-cy="client-email"
                                    matInput
                                    type="email"
                                    (input)="onMailInput($event)"
                                    formControlName="email"
                                    name="email"
                                    [matAutocomplete]="autoEmail"
                                    autocomplete="off">

                              <mat-error *ngIf="clientFormGroup.get('email').invalid">{{ getErrorMessageI18N('email') | translate }}</mat-error>


                            </mat-form-field>
                            <mat-form-field fxFlex="49" fxFlex.lt-md="100" appearance="outline">
                                <mat-label>{{ 'TELEPHONE' | translate }}</mat-label>

                                <mat-autocomplete #autoPhone="matAutocomplete" autoActiveFirstOption (optionSelected)="onUserAutocompleteSelected($event)">
                                  <mat-option *ngFor="let user of usersAutoCompletePhone" [value]="user">
                                    {{ user.phoneNumber }} - {{ user.name }}
                                  </mat-option>
                                </mat-autocomplete>

                                <input
                                    data-cy="client-phone"
                                    matInput
                                    type="tel"
                                    (input)="onPhoneInput($event)"
                                    formControlName="phone"
                                    name="phone"
                                    [matAutocomplete]="autoPhone"
                                    autocomplete="off">

                              <mat-error *ngIf="clientFormGroup.get('phone').invalid">{{ getErrorMessageI18N('phone') | translate }}</mat-error>

                            </mat-form-field>
                        </div>
                        </div>
                    </div>
                    <div>
                        <button
                            data-cy="button-submit-client-info"
                            *ngIf="!submitInProgress"
                            mat-stroked-button
                            color="primary">
                                {{ "CONTINUE" | translate | uppercase }}
                            </button>
                            <button mat-raised-button color="primary" disabled *ngIf="submitInProgress">
                                <span fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="10px">
                                    <div>{{ "SAVING" | translate }}...</div>
                                    <div>
                                        <mat-spinner diameter="20"></mat-spinner>
                                    </div>
                                </span>
                            </button>
                    </div>
                </form>

            </mat-step>

            <mat-step state="bike_scooter" [completed]="completedBikeStep">
                <ng-template matStepLabel>{{ "BIKE_INFO" | translate }}</ng-template>

                <div fxFlex="49" fxFlex.lt-md="100" class="p2">
                    <form [formGroup]="bikeFormGroup" (ngSubmit)="onSubmitBike()">
                        <div fxLayout="column">
                            <mat-form-field fxFlex appearance="outline">
                                <mat-label>{{ "BIKE_NAME" | translate }}</mat-label>
                                <input
                                    data-cy="bike-name"
                                    matInput
                                    formControlName="bike_name"
                                    (input)="onBikeNameInput($event)"
                                    name="bike_name"
                                    autocomplete="off"
                                    required>
                                <!-- <mat-hint>Hint</mat-hint> -->
                            </mat-form-field>
                            <div fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="space-between">
                                <mat-form-field fxFlex="49" fxFlex.lt-md="100" appearance="outline">
                                  <mat-label>{{ 'BIKE_TYPE' | translate }}</mat-label>
                                  <mat-select formControlName="bike_type" required>
                                    <!--                    <mat-option>&#45;&#45; None &#45;&#45;</mat-option>-->
                                    <mat-optgroup *ngFor="let subType of bikeTypes" label="{{ subType['subtype_I18NKey'] | translate}}">
                                      <mat-option *ngFor="let bike of subType.bikes" [value]="bike['value']">
                                        {{ bike['I18N_KEY'] | translate }}
                                      </mat-option>
                                    </mat-optgroup>
                                  </mat-select>
<!--                                    <input-->
<!--                                        data-cy="bike-type"-->
<!--                                        type="text"-->
<!--                                        matInput-->
<!--                                        placeholder="{{ 'BIKE_TYPE' | translate }}"-->
<!--                                        formControlName="bike_type"-->
<!--                                        required-->
<!--                                        [matAutocomplete]="autoGroup">-->
<!--                                    <mat-autocomplete #autoGroup="matAutocomplete">-->
<!--                                        <mat-optgroup *ngFor="let group of bikesTypeOptions$ | async" [label]="group.subtype">-->
<!--                                            <mat-option *ngFor="let name of group.names" [value]="name">-->
<!--                                                {{name}}-->
<!--                                            </mat-option>-->
<!--                                        </mat-optgroup>-->
<!--                                    </mat-autocomplete>-->
                                </mat-form-field>

                                <div fxFlex="49" fxFlex.lt-md="100" class="mat-form-field-wrapper">
                                    <input type="file"
                                        #inputBikePicture
                                        accept=".jpg,.png,.jpeg"
                                        formControlName="bike_picture"
                                        name="bike_picture"
                                        (change)="onAddBikePictureChange($event)"
                                        hidden>
                                    <button
                                        mat-stroked-button
                                        fxFlex="100"
                                        type="button"
                                        (click)="inputBikePicture.click();">
                                        <mat-icon class="muted">add_a_photo</mat-icon>
                                        <label class="pl1 muted">{{ "ADD_PHOTO" | translate }}</label>
                                    </button>

                                </div>
                            </div>

                            <!-- <mat-label>Main picture</mat-label> -->
                            <!-- <app-image-kit-image *ngIf="bike$ | async as bike" [imgSrc]="bike.mainPictureUrl" -->
                                <!-- w="100" h="100"></app-image-kit-image> -->
                        </div>
                        <div>
                            <button
                                data-cy="button-submit-bike-info"
                                mat-stroked-button
                                color="primary"
                                type="submit">
                                {{ 'CONTINUE' | translate | uppercase }}
                            </button>
                        </div>
                    </form>
                </div>

                <div fxFlex="49" fxFlex.lt-md="100" *ngIf="currenClientBikes.length > 0">
                    <app-bike-card *ngFor="let bike of currenClientBikes"
                        class="p2"
                        [bike]="bike"
                        [routerLink]="['/servicesheets/create', {bikeId: bike.id, budget: isBudget}]"
                        cardstyle="button"
                        defaultAction="{{ isBudget ? 'new_budget' : 'new_bss' }}">
                    </app-bike-card>
                </div>
            </mat-step>

            <!-- <mat-step>
                <ng-template matStepLabel>Done</ng-template>
                <p>You are now done.</p>
                <div>
                    <button mat-button matStepperPrevious>Back</button>
                    <button mat-button (click)="stepper.reset()">Reset</button>
                </div>
            </mat-step> -->

            <ng-template matStepperIcon="face">
                <mat-icon>face</mat-icon>
            </ng-template>
            <ng-template matStepperIcon="bike_scooter">
                <mat-icon>bike_scooter</mat-icon>
            </ng-template>
        </mat-vertical-stepper>
    </div>
</div>
