import {
  Component,
  ComponentFactory,
  ComponentFactoryResolver,
  OnDestroy,
  OnInit,
  ViewChild,
  ViewContainerRef
} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {ActivatedRoute, Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {CrmNotificationsService} from 'src/app/services/crm-notifications.service';
import {NativeInterfacesService} from 'src/app/services/native-interfaces.service';
import {TitleService} from 'src/app/services/title.service';
import {AbstractParentDetailComponent} from '../../components/abstract-parent-detail/abstract-parent-detail.component';
import {UsersService} from '../../services/users.service';
import {PaymentStatusCardComponent} from '../../components/payment-status-card/payment-status-card.component';
import {RentalTariff, RentalTariffPriceDuration} from '../../models/rental_tariff';
import {
  RentalTariffsDurationsComponent
} from '../../components/rental-tariffs-durations-list/rental-tariffs-durations.component';
import {RentalsTariffsService} from '../../services/rentals-tariffs.service';
import {takeUntil} from 'rxjs/operators';


@Component({
  selector: 'app-rentals-tariffs-detail',
  templateUrl: '../../components/abstract-parent-detail/abstract-parent-detail.component.html',
  styleUrls: ['../../components/abstract-parent-detail/abstract-parent-detail.component.scss']
})
export class RentalsTariffsDetailComponent extends AbstractParentDetailComponent<RentalTariff> implements OnInit, OnDestroy {

  constructor(
    protected titleService: TitleService,
    protected translate: TranslateService,
    protected notificationService: CrmNotificationsService,
    protected nativeInterfacesService: NativeInterfacesService,
    protected router: Router,
    protected dialog: MatDialog,
    protected progressDialog: MatDialog,
    protected route: ActivatedRoute,
    protected rentalsTariffsService: RentalsTariffsService,
    protected usersService: UsersService,
    private resolver: ComponentFactoryResolver,
    public native: NativeInterfacesService
  ) {
    super(
      titleService,
      translate,
      notificationService,
      nativeInterfacesService,
      router,
      dialog,
      progressDialog,
      route,
      rentalsTariffsService,
      usersService
    );
  }

  navBase = '/bikes_rental_tariffs/';

  titleI18N = 'TARIFF';
  titleIcon = '';

  @ViewChild(RentalTariffsDurationsComponent) childItemListRef: RentalTariffsDurationsComponent;
  @ViewChild('childListHost', {read: ViewContainerRef, static: false}) childListHost;
  @ViewChild('paymentCard') paymentCard: PaymentStatusCardComponent;

  primaryFab = null;
  secondaryFab = null;

  actions = [];

  excessDurationPriceWithVat = 0;

  loadChildListComponent(): void {
    // TODO: migrate use componentfactory to viewcontaineref.createcomponent
    //    https://stackoverflow.com/a/70947152/888245
    // Dynamically create the component, so we don't need multiple templates for different types of lists
    this.childListHost.clear();
    const factory: ComponentFactory<RentalTariffsDurationsComponent> = this.resolver.resolveComponentFactory(RentalTariffsDurationsComponent);
    this.childListRefComponentRef = this.childListHost.createComponent(factory);
    this.childListRefComponentRef.instance.parentApiContentTypeName = 'tariff';
    this.childListRefComponentRef.instance.parent = this.parentItem;
    this.childItemListRef = this.childListRefComponentRef.instance;

  }

  ngOnInit(): void {
    super.ngOnInit();

    this.parentItem$.subscribe(
      (tariff) => {
        this.excessDurationPriceWithVat = Math.round(tariff.excessDurationPrice * (1 + Number(tariff.vat)));
      }
    );
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }

  addTariffItem(rentalTariffDuration: RentalTariffPriceDuration): void {
    this.childItemListRef.addItemEnd(rentalTariffDuration);
  }

  onFabAction(actionId: string): boolean {
    return true;
  }

  onAction(actionId: string): boolean {
    console.log(`TODO: implement ${actionId}`);
    return true;
  }

  changedTariffName(): void {
    this.rentalsTariffsService.modify(this.parentItem.id, {name: this.parentItem.name}).pipe(takeUntil(this.onDestroy$)).subscribe(
      x => {
        this.parentItem = x;
        this.notificationService.successI18N('TARIFF_UPDATED');
      }
    );
  }

  excessDurationEveryHoursChanged(): void {
    this.rentalsTariffsService.modify(this.parentItem.id, {excessDurationEveryHours: this.parentItem.excessDurationEveryHours}).pipe(takeUntil(this.onDestroy$)).subscribe(
      x => {
        this.parentItem = x;
        this.notificationService.successI18N('TARIFF_UPDATED');
      }
    );
  }

  excessDurationPriceChanged(evt): void {
    this.excessDurationPriceWithVat = Number(evt.target.value);
    console.log('excessDurationPriceWithVat', this.excessDurationPriceWithVat);
    let priceWithoutVat = this.excessDurationPriceWithVat / (1 + Number(this.parentItem.vat));
    priceWithoutVat = Math.round(priceWithoutVat * 10000000000) / 10000000000;
    this.parentItem.excessDurationPrice = priceWithoutVat;
    console.log('priceWithouVat', priceWithoutVat);
    this.rentalsTariffsService.modify(this.parentItem.id, {excessDurationPrice: this.parentItem.excessDurationPrice}).pipe(takeUntil(this.onDestroy$)).subscribe(
      x => {
        this.parentItem = x;
        this.notificationService.successI18N('TARIFF_UPDATED');
      }
    );
  }

}
